import React from 'react';
import { RootProvider } from '@jarvis/react-portal-addons';
import { App } from 'src';
import resources from 'src/assets/locale';
import projectNames from './configs/projectNames';
import { ShellRootComponentProps } from 'src/types/shell';

export default function Root({ properties }: ShellRootComponentProps) {
  const { v1 } = window.Shell;

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <RootProvider
        localization={v1.localization}
        resources={resources}
        shell={v1}
        authProvider={v1.authProvider}
        stack={v1?.app?.getAuthStack()}
        properties={properties}
      >
        <App />
      </RootProvider>
    </section>
  );
}
