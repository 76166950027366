import React from 'react';
import { Body, Container, LoadingText } from './styles';
import { ProgressIndicator } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';

export const ModalLoadingContent = () => {
  const { t } = useI18n();

  return (
    <Container data-testid="modal-loading-content-container">
      <Body height="100px">
        <ProgressIndicator />
      </Body>
      <LoadingText className="caption">
        {t('virtualAgent.dialog.load.body')}
      </LoadingText>
    </Container>
  );
};
