import React, { useMemo } from 'react';
import { UseRootContextType } from '../types/mfeProps';
import { useRootContext } from '@jarvis/react-portal-addons';
import { APP_TYPES } from '../constants/AppTypes';
import { WhatsAppWidget } from '../components/Widgets/WhatsApp';
import { VirtualAgentWidget } from '../components/Widgets/VirtualAgent';
import { Footer } from '../components/Widgets/Footer';
import { VirtualAgentProvider } from '../contexts/VirtualAgent';

export const App = () => {
  const { properties }: UseRootContextType = useRootContext();

  const SelectedComponent = useMemo(() => {
    switch (properties?.type) {
      case APP_TYPES.WhatsAppWidget:
        return <WhatsAppWidget />;
      case APP_TYPES.VirtualAgentWidget:
        return <VirtualAgentWidget />;
      case APP_TYPES.Footer:
        return <Footer />;
      default:
        return null;
    }
  }, [properties?.type]);

  return <VirtualAgentProvider>{SelectedComponent}</VirtualAgentProvider>;
};

export default App;
