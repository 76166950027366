import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal } from '@veneer/core';
import { ModalLoadingContent } from './LoadingContent';
import { ModalSuccessContent } from './SuccessContent';
import { ModalErrorContent } from './ErrorContent';
import { createVALink } from '../../../../utils/VirtualAgent';
import { useRootContext } from '@jarvis/react-portal-addons';
import { UseRootContextType } from '../../../../types/AppTypes';

type VirtualAgentModalPropsType = {
  show: boolean;
  setShow: (show: boolean) => void;
};

type VaStateType = {
  loading: boolean;
  error: boolean;
  link: string | null;
};

export const VirtualAgentModal = ({
  show,
  setShow
}: VirtualAgentModalPropsType) => {
  const [vaState, setVAState] = useState<VaStateType>({
    loading: true,
    error: false,
    link: null
  });

  const {
    authProvider,
    stack,
    localization,
    shell: { navigation }
  }: UseRootContextType = useRootContext();

  const setLink = (link: string) => {
    setVAState({
      error: false,
      link,
      loading: false
    });
  };

  const setError = () => {
    setVAState({
      error: true,
      link: null,
      loading: false
    });
  };

  const setLoading = () => {
    setVAState({
      error: false,
      link: null,
      loading: true
    });
  };

  const closeModal = useCallback(() => {
    setShow(false);
    setLoading();
  }, [setShow]);

  useEffect(() => {
    const fetchLink = async () => {
      const vaLink = await createVALink({
        authProvider,
        language: localization?.language,
        location: navigation?.location,
        stack
      });

      return vaLink ? setLink(vaLink) : setError();
    };

    if (show && vaState.loading) fetchLink();
  }, [
    authProvider,
    localization?.language,
    navigation.location,
    stack,
    vaState.loading,
    show
  ]);

  const ModalContent = useMemo(() => {
    if (vaState.error) {
      return <ModalErrorContent closeModal={closeModal} />;
    }

    if (vaState.loading) {
      return <ModalLoadingContent />;
    }

    return (
      <ModalSuccessContent
        link={vaState.link}
        closeModal={closeModal}
      />
    );
  }, [closeModal, vaState.error, vaState.link, vaState.loading]);

  return <Modal show={show}>{ModalContent}</Modal>;
};
