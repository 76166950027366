import { Stack } from '../types/shell';
import axios from 'axios';

const getBaseUrl = (stack: Stack) => {
  if (stack === Stack.prod) {
    return 'https://instantink.hpconnected.com';
  }

  let stackName = 'pie';

  if (stack === Stack.stage) {
    stackName = 'stage';
  }

  return `https://instantink-${stackName}1.hpconnected${stackName}.com`;
};

export type InstantInkContextType = {
  instantInk: unknown;
};

export const getInstantInkContext = async ({
  authProvider,
  stack,
  userId
}): Promise<InstantInkContextType> => {
  try {
    const accessToken = await authProvider.getAccessToken();
    const response = await axios.get(
      `${getBaseUrl(stack)}/api/users/virtual_agent/context/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
