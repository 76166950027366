import { DeviceApiClient } from '@jarvis/web-stratus-client';

export type ModelDetailsType = {
  modelName: string;
  modelNumber: string;
  colorSupported: string;
  supplyType: string;
  displayProfile: string;
  platformIdentifier: string;
  signalingProfile: string;
  cdmPubSubVersion: string;
  eprintSupported: boolean;
  sipsSupported: boolean;
  sipsDeviceClass: string;
  printOnTheGoSupport: string;
  bleHPSpecVersion: string;
  printContractVersion: string;
  images: string[];
  displayMessageFormat: string;
  benefitsControlModel: string;
  supplies: [
    {
      colorCode: string;
      slot: number;
    }
  ];
  supportedDataModels: string[];
  pushScanSupported: string;
  helpPageUrl: string;
  productLine: string;
  deviceSegment: string;
  makeAndModel: string;
  launchDate: string;
  countries: string[];
  infoOwner: string;
  derivativeName: string;
  deviceFullName: string;
  productShortName: string;
  seriesName: string;
  codeName: string;
  programs: string[];
  solutions: string[];
  oid: string;
  printerSeriesOid: string;
  releaseStatus: string;
  mfgBizModel: string;
  offer: number;
  lastUpdatedTime: number;
  resetPageUrl: string;
  deviceCapabilities: [
    {
      name: string;
      support: string;
    }
  ];
  onboardingCapabilities: {
    prohibitedClients: string[];
    allowedClients: string[];
  };
  connectivityFamily: string[];
  supplyDelivery: string;
  remoteAttestationSupport: string;
  wechatJpegDirectPrintEnabled: string;
  hpWebservicesSupport: string;
  productFamily: string;
};

export const getModelDetails = async ({
  authProvider,
  stack,
  productNumber
}): Promise<ModelDetailsType> => {
  try {
    const client = new DeviceApiClient(stack, authProvider);
    const response = await client?.getModelDetails(productNumber);
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
