import styled from 'styled-components';
import { SideMenuItem } from '@veneer/core';

export const VirtualAgenteSideMenuItem = styled(SideMenuItem)`
  * {
    color: #027aae;
  }

  && a {
    align-items: center;
  }
`;

export const MenuText = styled.span`
  white-space: pre-line;
`;
