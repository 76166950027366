import React from 'react';
import { useRootContext } from '@jarvis/react-portal-addons';
import { UseRootContextType } from '../../../types/mfeProps';

export const FooterLink = ({
  href,
  content,
  ...rest
}: {
  href: string;
  content: string;
}) => {
  const { shell }: UseRootContextType = useRootContext();
  const dataTestId = rest['data-testid'] || 'footer-link';

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    href: string
  ) => {
    if (href.startsWith('https')) {
      return;
    }

    e?.nativeEvent?.stopImmediatePropagation();
    e?.stopPropagation();
    e?.preventDefault();

    shell.navigation.push(href);
  };

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="caption"
      onClick={(e) => handleClick(e, href)}
      data-testid={dataTestId}
    >
      {content}
    </a>
  );
};
