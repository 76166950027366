import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Header = styled.h4`
  padding-bottom: 20px;
`;

export const Body = styled.div<{
  height?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height || '100%'};

  img {
    box-sizing: border-box;
    padding-right: 15px;
    width: 60px;
  }
`;

export const Footer = styled.div`
  padding-top: 10px;

  > button {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  > a.button {
    align-items: center;
    background-color: #027aae;
    border-color: #027aae;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    line-height: 24px;
    margin: 10px 5px 0 5px;
    min-width: 112px;
    padding: 11px 31px;
    position: relative;
    text-align: center;
    transition: background-color 0.3s ease, border-color 0.3s ease,
      color 0.3s ease;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: 0;
    box-shadow: none;

    &:active {
      background-color: #014667;
      border-color: #014667;
    }

    &:hover {
      background-color: #035c84;
      border-color: #035c84;
      text-decoration: none;
    }

    &.secondary {
      background-color: transparent;
      color: #027aae;

      &:active {
        background-color: #f2f8fb;
        color: #014667;
      }

      &:hover {
        background-color: #f2f8fb;
        color: #035c84;
      }
    }
  }
`;

export const LoadingText = styled.p`
  text-align: center;
`;
