import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: 'transparent';
`;

export const CopyrightText = styled.p`
  text-align: center;

  @media (max-width: ${tokens.xlMin}) {
    margin-top: 5px;
  }
`;

export const FooterContentContainer = styled.div<{ showVAFooter: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 31px;
  margin-bottom: ${(props) => (props.showVAFooter ? '48px' : 'initial')};

  @media (max-width: 1450px) {
    flex-direction: column;

    p > {
      text-align: center;
    }
  }

  @media (max-width: ${tokens.xlMin}) {
    flex-direction: column-reverse;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > a {
    color: ${tokens.colorGray7};
    text-decoration: none;
    padding: 2px 8px;

    &:hover {
      color: ${tokens.colorHpBlue7};
    }
  }
`;
