/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { IconChat } from '@veneer/core';
import { VirtualAgenteSideMenuItem, MenuText } from './styles';
import { SideMenu } from '../../SideMenu';
import { useI18n } from '@jarvis/react-portal-addons';
import { VirtualAgentContext } from '../../../contexts/VirtualAgent';

export const VirtualAgentWidget = () => {
  const { openVA } = useContext(VirtualAgentContext);
  const { t } = useI18n();

  return (
    <React.Fragment>
      <SideMenu>
        <VirtualAgenteSideMenuItem
          className="caption"
          label={
            (
              <MenuText data-testid="virtual-agent-widget-label">
                {`${t('virtualAgent.headers.mainHeader')}\n${t(
                  'virtualAgent.title'
                )}`}
              </MenuText>
            ) as any
          }
          icon={<IconChat />}
          onClick={openVA}
        />
      </SideMenu>
    </React.Fragment>
  );
};
