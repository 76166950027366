import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client';

export type UserTenantType = {
  user: {
    resourceId: string;
    idpId: string;
    idpType: string;
    email: {
      value: string;
      verified: boolean;
    };
    phoneNumber: {
      value: string;
    };
    familyName: string;
    givenName: string;
    userName: string;
    locale: string;
    createdAt: string;
    updatedAt: string;
    status: string;
  };
  userTenantDetail: {
    resourceId: string;
    systemTenantResourceId: string;
    fqTenantId: string;
    tenantResourceId: string;
    tenantType: string;
    userResourceId: string;
    roleResourceId: string;
    roleCategory: string;
    rolePermId: string;
    state: string;
    activationDateTime: string;
    createdAt: string;
    updatedAt: string;
  };
};

export const getUserTenant = async ({
  authProvider,
  stack
}): Promise<UserTenantType> => {
  try {
    const client = new UserTenantMgtSvcClient(stack, authProvider);
    const response = await client?.getCurrentActiveUserWithTenant();
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
