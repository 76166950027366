import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Footer } from '@veneer/core';

export const CopyrightText = styled.span`
  font-size: ${tokens.fontSize1};
  color: ${tokens.colorGray7};
`;

export const StyledVAButton = styled.button`
  font-size: 16px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  text-align: right;
  color: #027aae;
`;

export const StyledFooter = styled(Footer).attrs(() => ({
  customStyle: {
    height: '48px',
    padding: '0 16px'
  }
}))`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  box-shadow: 0 -3px 6px #0000000d;

  @media (min-width: ${tokens.xlMin}) {
    display: none;
  }
`;
