import styled from 'styled-components';
import { SideMenuItem } from '@veneer/core';

export const WhatsAppSideMenuItem = styled(SideMenuItem)`
  span {
    span {
      color: #027aae;
    }
  }
`;
