import React, { useEffect } from 'react';
import { Button } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import { Container, Header, Body, Footer } from './styles';
import { Images } from '../../../../assets/images';
import {
  triggerCancelEvent,
  triggerStartChatEvent,
  triggerVirtualAgentSuccessModalDisplayedEvent
} from '../../../../utils/analytics';

type ModalSuccessContentPropsType = {
  link: string;
  closeModal: () => void;
};

export const ModalSuccessContent = ({
  link,
  closeModal
}: ModalSuccessContentPropsType) => {
  const { t } = useI18n();

  useEffect(() => {
    triggerVirtualAgentSuccessModalDisplayedEvent();
  }, []);

  return (
    <Container data-testid="modal-success-content-container">
      <Header className="title-small">
        {t('virtualAgent.dialog.ready.header')}
      </Header>
      <Body className="caption">
        <img
          alt="Virtual Assistant"
          src={Images.virtualAssistant}
        />
        {t('virtualAgent.dialog.ready.body')}
      </Body>
      <Footer>
        <Button
          aria-label={t('virtualAgent.dialog.ready.secondary')}
          appearance="secondary"
          onClick={() => {
            triggerCancelEvent();
            closeModal();
          }}
        >
          {t('virtualAgent.dialog.ready.secondary')}
        </Button>
        <a
          className="button"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            triggerStartChatEvent();
            closeModal();
          }}
        >
          {t('virtualAgent.dialog.ready.primary')}
        </a>
      </Footer>
    </Container>
  );
};
