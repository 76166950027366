import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';

export type AccountType = {
  resourceId: string;
  parentTenantResourceId: string;
  type: string;
  accountName: string;
  regionId: string;
  countrySet: [string];
  description: string;
  state: string;
  programId: string;
};

export const getAccount = async ({
  authProvider,
  stack
}): Promise<AccountType> => {
  try {
    const client = new AccountMgtSvcClient(stack, authProvider);
    const response = await client?.getAccount();
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
