/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CustomImage } from '@veneer/core';
import { WhatsAppSideMenuItem } from './styles';
import { Images } from '../../../assets/images';
import { useI18n } from '@jarvis/react-portal-addons';
import { SideMenu } from '../../SideMenu';

export const WhatsAppWidget = () => {
  const { t } = useI18n();

  return (
    <SideMenu>
      <WhatsAppSideMenuItem
        className="caption"
        label={
          (
            <span data-testid="whatsapp-widget-label">
              {t('whatsAppSupport.line1')}
              <br />
              WhatsApp
              <br />
              <span>+44 20 3823 6694</span>
            </span>
          ) as any
        }
        icon={<CustomImage src={Images.whatsAppSupport} />}
        link={
          <a
            href="https://api.whatsapp.com/send?phone=442038236694"
            rel="noopener noreferrer"
          />
        }
        urlTarget="_blank"
      />
    </SideMenu>
  );
};
