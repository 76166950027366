import styled from 'styled-components';
import { SideMenu } from '@veneer/core';

export const CustomSideMenu = styled(SideMenu)`
  > div {
    display: none;
  }

  > nav {
    padding: 0;
  }

  && {
    left: 0;
  }
`;
