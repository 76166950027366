import { getLaunchUrl } from '../api/VirtualAgent';
import { Stack } from '../types/shell';
import { UseRootContextType } from '../types/mfeProps';

type CreateVALinkPropsType = {
  location: unknown;
  language: string;
  stack: Stack;
  authProvider: UseRootContextType['authProvider'];
};

const getOS = () => {
  const { userAgent } = window.navigator;
  const osArray = [
    { name: 'Android', regex: /Android/i },
    { name: 'iOS', regex: /iPhone/i },
    { name: 'Windows', regex: /Win/i },
    { name: 'MacOS', regex: /Mac/i },
    { name: 'UNIX', regex: /X11/i },
    { name: 'Linux', regex: /Linux/i }
  ];

  const os = osArray.find((os) => os.regex.test(userAgent));
  return os ? os.name : 'UnknownOS';
};

const findVALaunchPoint = (location) =>
  location.pathname === '/' ? 'UCDEHome' : location.pathname.slice(1);

export const createVALink = async ({
  location,
  language,
  stack,
  authProvider
}: CreateVALinkPropsType): Promise<string> => {
  const launchUrl = await getLaunchUrl({
    language,
    launchPoint: findVALaunchPoint(location),
    devOs: getOS(),
    authProvider,
    stack: stack || (await window.Shell.v1.app.getAuthStack())
  });

  if (!launchUrl) return null;

  const stackName = stack === Stack.prod ? '' : '-stg';
  const linkBase = `https://virtualagent${stackName}.hpcloud.hp.com`;
  return `${linkBase}${launchUrl}`;
};
