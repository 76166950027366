import React from 'react';
import { CustomSideMenu } from './styles';

type SideMenuPropsType = {
  children: React.ReactNode;
};

export const SideMenu = ({ children }: SideMenuPropsType) => {
  return (
    <CustomSideMenu
      position="relative"
      showIcons
      hideAppHeader
    >
      {children}
    </CustomSideMenu>
  );
};
