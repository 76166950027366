import React, { useContext } from 'react';
import { VirtualAgentContext } from '../../../../contexts/VirtualAgent';
import { useI18n } from '@jarvis/react-portal-addons';
import { StyledFooter, StyledVAButton } from './styles';

export const VirtualAgentFooter = () => {
  const { t } = useI18n();
  const { openVA } = useContext(VirtualAgentContext);

  return (
    <StyledFooter
      data-testid="consumer-dashboard-footer-virtual-agent"
      appearance="minimal"
      mainContent={{
        rightArea: (
          <StyledVAButton
            data-testid="consumer-dashboard-footer-virtual-agent-button"
            onClick={openVA}
            type="button"
            className="body"
          >
            {t('virtualAgent.title')}
          </StyledVAButton>
        )
      }}
    />
  );
};
