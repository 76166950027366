import { UserTenantType } from '../api/userTenantApicall';
import { getUserTenant } from '../api/userTenantApicall';
import { getDevices } from '../api/devicesApiCall';
import { getAccount } from '../api/accountApiCall';
import { getInstantInkContext } from '../api/instantInkContextApiCall';
import { getModelDetails } from '../api/getModelDetailsApiCall';
import { Stack } from '../types/shell';
import { UseRootContextType } from '../types/mfeProps';
import { VIRTUAL_AGENT } from '../constants/VirtualAgent';
import axios from 'axios';

type GetLaunchUrlPropsType = {
  language: string;
  launchPoint: string;
  devOs: string;
  authProvider: UseRootContextType['authProvider'];
  stack: Stack;
};

type GetBotContextPropsType = {
  launchPoint: string;
  devOs: string;
  authProvider: UseRootContextType['authProvider'];
  stack: Stack;
};

type GetVirtualAgentBotConversationPropsType = {
  language: string;
  country: string;
  botContext: unknown;
  stack: Stack;
};

const getVirtualAgentBotConversation = async ({
  language,
  country,
  botContext,
  stack
}: GetVirtualAgentBotConversationPropsType): Promise<string> => {
  try {
    const stackName = stack === Stack.prod ? '' : `${Stack[stack]}-`;
    const response = await axios.post(
      `https://${stackName}us1.api.ws-hp.com/portalsvc/session/v1/virtual-agent/conversation`,
      {
        clientId: VIRTUAL_AGENT.BOT_CONVERSATION.clientId,
        language: language,
        country: country,
        botClient: VIRTUAL_AGENT.BOT_CONVERSATION.botClient,
        botSubClient: VIRTUAL_AGENT.BOT_CONVERSATION.botSubClient,
        botContext
      }
    );

    return response?.data;
  } catch (error) {
    return null;
  }
};

export const getBotContext = async ({
  launchPoint,
  devOs,
  authProvider,
  stack
}: GetBotContextPropsType) => {
  const devices = await getDevices({ authProvider, stack });
  const firstDevice = devices?.[0];
  const productNumber = firstDevice?.identity?.makeAndModel?.number;
  let product = null;

  if (productNumber) {
    const modelDetails = await getModelDetails({
      authProvider,
      stack,
      productNumber
    });

    product = {
      productNumber: firstDevice.identity?.makeAndModel?.number,
      productName: modelDetails?.modelName,
      productOid: modelDetails?.oid,
      serialNumber: firstDevice.identity?.serialNumber,
      productSeriesName: modelDetails?.seriesName,
      productSeriesOid: modelDetails?.printerSeriesOid
    };
  }

  const userTenant = await getUserTenant({ authProvider, stack });
  const user: UserTenantType['user'] = userTenant?.user;

  const account = await getAccount({ authProvider, stack });
  const regionId = account?.regionId?.toUpperCase();

  let instantInkContext = undefined;

  if (user?.idpId) {
    instantInkContext = await getInstantInkContext({
      authProvider,
      stack,
      userId: user.idpId
    });
  }

  return {
    firstName: user?.givenName,
    lastName: user?.familyName,
    email: user?.email.value,
    phoneNumber: user?.phoneNumber?.value,
    hpSmart: {
      launchPoint: launchPoint || '',
      devOs: devOs || ''
    },
    product: {
      ...product,
      countryCode: regionId,
      languageCode: user?.locale
        ?.substring(0, user?.locale.indexOf('_'))
        ?.toLowerCase(),
      countryOfPurchase: regionId
    },
    instantInk: instantInkContext?.instantInk,
    state: {
      botClient: VIRTUAL_AGENT.BOT_CONVERSATION.botClient,
      botSubClient: VIRTUAL_AGENT.BOT_CONVERSATION.botSubClient
    }
  };
};

export const getLaunchUrl = async ({
  language,
  launchPoint,
  devOs,
  authProvider,
  stack
}: GetLaunchUrlPropsType): Promise<string | null> => {
  const botContext = await getBotContext({
    launchPoint,
    devOs,
    authProvider,
    stack
  });

  const country = botContext?.product?.countryCode;

  if (!country) return null;

  const launchUrl = await getVirtualAgentBotConversation({
    language: language || botContext?.product?.languageCode,
    country,
    botContext,
    stack
  });

  return launchUrl || null;
};
