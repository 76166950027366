import React, { useEffect } from 'react';
import { Button } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import { Container, Header, Body, Footer } from './styles';
import {
  triggerGetSupportEvent,
  triggerOkEvent,
  triggerVirtualAgentErrorModalDisplayedEvent
} from '../../../../utils/analytics';

type ModalErrorContentPropsType = {
  closeModal: () => void;
};

export const ModalErrorContent = ({
  closeModal
}: ModalErrorContentPropsType) => {
  const { t } = useI18n();

  useEffect(() => {
    triggerVirtualAgentErrorModalDisplayedEvent();
  }, []);

  return (
    <Container data-testid="modal-error-content-container">
      <Header className="title-small">
        {t('virtualAgent.dialog.fail.header')}
      </Header>
      <Body className="caption">{t('virtualAgent.dialog.fail.body')}</Body>
      <Footer>
        <a
          className="button secondary"
          href="https://support.hp.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            triggerGetSupportEvent();
            closeModal();
          }}
        >
          {t('virtualAgent.dialog.fail.secondary')}
        </a>
        <Button
          data-testid="modal-error-content-button"
          aria-label={t('virtualAgent.dialog.fail.primary')}
          onClick={() => {
            triggerOkEvent();
            closeModal();
          }}
        >
          {t('virtualAgent.dialog.fail.primary')}
        </Button>
      </Footer>
    </Container>
  );
};
